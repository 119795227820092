import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Head from '../components/head';

const NotFound = () => {
    return (
        <Layout>
            <div className='not-found'>
                <div>
                    <Head title='Not Found' />
                    <p>404 - Page Not Found! <Link to='/'>Go Home.</Link></p>
                </div>
            </div>
        </Layout>
    );
};

export default NotFound;